import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchNotifications = async (id) => {
  const response = await fetch(`${baseUrl}/api/client/getnotifications/${id}`, {
    headers:jwtHeaders(),
  });
  const respData = response.json();
  return respData;
};

export const fetchAccountExpiration = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/branch/branchexpiration/${branchId}`, {
    headers: jwtHeaders(),
  });
  if (!response.ok) {
    console.error("Error fetching account expiration:", response.status, response.statusText);
    throw new Error(`Failed to fetch: ${response.statusText}`);
  }
  const respData = await response.json(); // Await the JSON response
  return respData;
};


