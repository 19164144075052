import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import { useNavigate } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import TextInputs from 'src/components/inputs/TextInputs';
import InputSelect from '../Settings/OwnerProfile/CustomInputSelect';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const initialUpdatedData = {
  branchName: '',
  branchPhone: '', // Assuming branchPhone is another field, adjust as needed
  address: '',
  googleReviewLink: '',
  perRewardPointsPrice: 0,
  websiteLink: '',
};

const FieldData = ({ data, setData, getData, ownerData }) => {
  const { deleteBranch, updateBranch, setShowMessage } = useMain();

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [updateFormData, setUpdateFormData] = useState(initialUpdatedData);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const naviage = useNavigate();
  console.log(data, 'dataaa')

  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }

  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      // const res = await deleteBranch(deleteId);
      const res = await deleteBranch(ownerData?._id, deleteId);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Branch deleted successfully',
          messageType: 'success',
        });
        // setData(data?.filter((d) => d?._id !== deleteId));
        setData(data?.filter((d) => d?._id !== ownerData._id));
        await getData(); // Refresh the data after deletion
      } else {
        setShowMessage({
          message: res.message || 'Branch not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };
  console.log(deleteId, 'ddd')

  const handleDetails = (id) => {
    naviage(`/branch/${id}`);
  };

  const handleUpdate = (item) => {
    console.log(item, "item")
    setSelectedItemId(item._id);
    const { branchName, branchPhone, address, googleReviewLink, perRewardPointsPrice, gstNumber,websiteLink} = item;
    setUpdateFormData((pre) => ({
      ...pre,
      branchName,
      branchPhone,
      address,
      googleReviewLink,
      perRewardPointsPrice,
      gstNumber,
      confirmGstNumber: gstNumber,
      websiteLink
    }));
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const hadleupdateData = async () => {
    setLoading(true);
    try {
      console.log(updateFormData, 'updatedDAta');
      const res = await updateBranch(updateFormData, selectedItemId);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Branch Details updated successfully',
          messageType: 'success',
        });
        setData([]);
        getData();
      } else {
        setShowMessage({
          message: res.message | 'Branch details not Updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };
  console.log(updateFormData, 'updateFormData')

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'BranchName',
              'BranchEmail',
              'Create Date',
              'ActiveStatus',
              'Remaining Duration',
              'Subscription Last Date',
              'Message Sent',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '10%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            const originalSubsDate = item?.subscriptionDetails?.duration?.endDate;

            // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
            let formattedSubsDate = '';
            if (originalSubsDate) {
              const [year, month, day] = originalSubsDate.split('-');
              formattedSubsDate = `${day}-${month}-${year}`;
            }

            const originalCreatedDate = convertDateFormat(item?.createdAt);
            let formattedCreatedDate = '';
            if (originalCreatedDate) {
              const [year, month, day] = originalCreatedDate.split('-');
              formattedCreatedDate = `${day}-${month}-${year}`;
            }

            return (
              <tr key={item._id}>
                <td style={{ width: '10%' }}>{item?.branchName}</td>
                <td style={{ width: '10%' }}>{item?.branchEmail}</td>
                <td style={{ width: '10%' }}>{formattedCreatedDate}</td>
                <td style={{ width: '10%' }}>{item?.activeStatus}</td>
                <td style={{ width: '10%' }}>
                  {item?.remainingMonths > 1
                    ? `${item?.remainingMonths} months`
                    : `${item?.remainingMonths} month`}
                  {item?.remainingDays > 1
                    ? ` ${item?.remainingDays} days`
                    : ` ${item?.remainingDays} day`}
                </td>

                <td style={{ width: '10%' }}>{formattedSubsDate}</td>
                <td style={{ width: '10%' }}>{item?.sentSmsCount}</td>
                {/* <td style={{ width: '10%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    handleDetails={handleDetails}
                    field={item?._id}
                  />
                </td> */}
                <td style={{
                  // width: '100%',
                  width: '10%'
                }}>
                  <IconButton onClick={() => handleDetails(item._id)} color="primary" title="View Details">
                    <AiOutlineInfoCircle style={{ color: '#696969', fontSize: '1.1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ margin: '-2px' }}>
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ margin: '-6px' }}>
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this Branch?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* ---------------------- Branch Details Dialog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Branch Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update Branch details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Branch Name'}
                  name={'branchName'}
                  value={updateFormData?.branchName}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Phone'}
                  name={'branchPhone'}
                  value={updateFormData?.branchPhone}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Address'}
                  name={'address'}
                  value={updateFormData?.address}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Google Review Link'}
                  name={'googleReviewLink'}
                  value={updateFormData?.googleReviewLink}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Gst Number'}
                  name={'gstNumber'}
                  value={updateFormData?.gstNumber}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Confirm Gst Number'}
                  name={'confirmGstNumber'}
                  value={updateFormData?.confirmGstNumber}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label style={{ padding: '2px', display: 'block', marginBottom: '0' }}>
                  Per Reward Point Value
                </label>
                <InputSelect
                  name="perRewardPointsPrice"
                  value={updateFormData.perRewardPointsPrice}
                  onChange={handleChange}
                  options={[
                    { value: 0.05, label: '0.05' },
                    { value: 0.10, label: '0.10' },
                    { value: 0.15, label: '0.15' },
                    { value: 0.20, label: '0.20' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title="Website Link"
                  name="websiteLink"
                  value={updateFormData.websiteLink}
                  handleChange={handleChange}
                  type="text"
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={hadleupdateData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FieldData;

function convertDateFormat(originalDate) {
  // Parse the original date string
  const dateObject = new Date(originalDate);

  // Extract day, month, and year components
  const day = dateObject.getUTCDate();
  const month = dateObject.getUTCMonth() + 1; // Months are zero-based, so add 1
  const year = dateObject.getUTCFullYear();

  // Format the components as dd-mm-yyyy
  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

  return formattedDate;
}
